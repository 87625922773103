import {IWixAPI} from '@wix/yoshi-flow-editor'
import {
  getAboutText,
  getPreliminaryInvoice,
  getTaxConfig,
  isEndDateHidden,
  isEventLocationOnline,
  isLocationTbd,
  isRecurringEvent,
} from '@wix/wix-events-commons-statics'
import {EventStatus, EventType, RegistrationStatus, LocationType} from '@wix/events-types'
import {addQueryParams} from '@wix/panda-js-utils'
import {getLanguage, getPageUrl} from '../../../../commons/utils/wix-code-api'
import {State} from '../types'

interface RenderSEOTagsParams {
  wixCodeApi: IWixAPI
  state: State
}

export const renderSEOTags = async ({wixCodeApi, state}: RenderSEOTagsParams) => {
  const pageUrl = await getPageUrl(wixCodeApi)
  const locationQueryLang = getLanguage(wixCodeApi)
  const itemData = getItemData(state, pageUrl, locationQueryLang)
  const seoData = state.event.seoSettings?.advancedSeoData ?? {}

  if (itemData) {
    wixCodeApi.seo.renderSEOTags({
      itemType: 'EVENTS_PAGE',
      itemData,
      seoData,
    })
  }
}

export const setRobotsNoIndex = (wixCodeApi: IWixAPI) => {
  const METATAG_NAME_ROBOTS = 'robots'
  const METATAG_CONTENT_NOINDEX = 'noindex'

  const metaTags = wixCodeApi.seo.metaTags || []
  const robotsIndex = metaTags.findIndex(tag => tag?.name === METATAG_NAME_ROBOTS)
  if (robotsIndex > -1) {
    metaTags[robotsIndex].content = METATAG_CONTENT_NOINDEX
  } else {
    // @ts-expect-error
    metaTags.push({name: METATAG_NAME_ROBOTS, content: METATAG_CONTENT_NOINDEX})
  }
  wixCodeApi.seo.setMetaTags?.(metaTags)
}

const getItemData = (state: State, pageUrl: string, language: string | undefined) => {
  try {
    return {
      event: transformEvent(state.event, pageUrl, language),
      tickets: state.tickets?.length ? transformTickets(state.tickets, state.event) : state.tickets,
    }
  } catch (e) {
    console.warn(e)
    return null
  }
}

export const transformEvent = (event: wix.events.Event, pageUrl: string, language: string | undefined) => ({
  ...event,
  about: getAboutText(event),
  status: EventStatus[event.status],
  eventPageUrl: {
    base: pageUrl,
    path: addQueryParams(`/${event.slug}`, {lang: language}),
  },
  registration: {
    ...event.registration,
    type: EventType[event.registration.type],
    status: RegistrationStatus[event.registration.status],
  },
  location: {
    ...event.location,
    type: isLocationTbd(event) ? undefined : LocationType[event.location.type],
  },
  scheduling: {
    ...event.scheduling,
    config: {
      ...event.scheduling.config,
      endDate: isEndDateHidden(event) ? undefined : event.scheduling.config.endDate,
    },
  },
  ...(event.onlineConferencing
    ? {
        onlineConferencing: {
          ...event.onlineConferencing,
          ...(isEventLocationOnline(event) && isRecurringEvent(event)
            ? {config: {...event.onlineConferencing.config, enabled: true}}
            : null),
        },
      }
    : null),
})

export const transformTickets = (tickets: wix.events.ticketing.TicketDefinition[], event: wix.events.Event) => {
  return tickets.map(ticket => ({
    ...ticket,
    finalPrice: {
      ...getPreliminaryInvoice(tickets, {[ticket.id]: {quantity: 1}}, getTaxConfig(event)).grandTotal,
    },
  }))
}
